import Link from "next/link"
import styled from "styled-components"
import Typography from "@/styled/Typography"
import { CallUsButton, PrimaryButton } from "@/styled/Buttons"
import { Row, SectionContainer } from "@/styled/Grid"
import { useRouter } from "next/router"
import { sendEvent } from "@/scripts/GoogleAnalytics"
import Col from "@/styled/Grid/Col"
import Image from "next/image"

const Text = styled(Typography)`
  font-weight: 400;
`

const Cta = styled.div`
  text-align: center;
  a {
    border: 2px solid ${({ theme }) => theme.colors.deepPink};
  }
`

const SideImage = styled.div`
  position: relative;
  img {
    min-width: 400px;
  }
`

export default function AboutUs() {
  const router = useRouter()

  const handleClick = e => {
    e.preventDefault()
    router.push("/nuestros-fisios")
  }

  const onClickCTA = () => {
    sendEvent("incoming call", { event_category: "Home", event_label: "incoming call about us" })
  }

  return (
    <SectionContainer style={{ overflow: "visible" }}>
      <Typography variant="title2" className="mb-2" $weight="medium" center>
        Sobre nosotros
      </Typography>
      <Typography className="mb-5" center>
        Tu centro de fisioterapia en
        <Link href="/fisioterapia-barcelona" passHref>
          <> Barcelona</>
        </Link>
        ,
        <Link href="/fisioterapia-sant-cugat" passHref>
          <> Sant Cugat </>
        </Link>
        y
        <Link href="/fisioterapia-sant-joan-despi" passHref>
          <> Sant Joan Despí</>
        </Link>
      </Typography>
      <Row style={{ alignItems: "center" }}>
        <Col xs={12} md={7}>
          <Typography variant="title3" $weight="medium" className="mb-5">
            El mejor tratamiento sintiéndote como en casa
          </Typography>
          <Text className="mb-4">
            <b>
              Bienvenido a Physio WOW, tu centro de fisioterapia de confianza en
              <Link href="/fisioterapia-barcelona" passHref>
                <> Barcelona</>
              </Link>
              ,
              <Link href="/fisioterapia-sant-cugat" passHref>
                <> Sant Cugat </>
              </Link>
              y
              <Link href="/fisioterapia-sant-joan-despi" passHref>
                <> Sant Joan Despí</>
              </Link>
            </b>
            . Estamos aquí para ayudarte a aliviar el dolor y mejorar tu bienestar físico y emocional.
          </Text>
          <Text className="mb-4">
            En <b>Physio WOW</b>, contamos con fisioterapeutas altamente cualificados, especializados en lesiones
            deportivas, dolores musculares, de espalda y mucho más. Ofrecemos atención personalizada en un ambiente
            acogedor y seguro, desde tu primera visita.
          </Text>
          <Text className="mb-4">
            Nos enfocamos en tratar las causas subyacentes de tus problemas, desarrollando un plan de tratamiento
            adaptado a tus necesidades.
          </Text>
          <Text className="mb-4">
            Con tres centros en
            <Link href="/fisioterapia-barcelona" passHref>
              <> Barcelona</>
            </Link>
            ,
            <Link href="/fisioterapia-sant-cugat" passHref>
              <> Sant Cugat </>
            </Link>
            y
            <Link href="/fisioterapia-sant-joan-despi" passHref>
              <> Sant Joan Despí</>
            </Link>
            , estamos a tu disposición para brindarte un servicio excepcional.{" "}
            <b>¡Agenda tu cita hoy mismo y comienza tu camino hacia una vida sin dolor!</b>
          </Text>
          <PrimaryButton size="md" className="mb-4" onClick={handleClick}>
            Nuestros fisios
          </PrimaryButton>
        </Col>
        <Col xs={12} md={5} className="d-none d-md-block">
          <Image
            alt="Physio WOW top"
            src="https://res.cloudinary.com/physio-wow/image/upload/w_400,q_auto,f_auto/static/anathomy"
            width={400}
            height={400}
            style={{ minWidth: "400px" }}
          />
        </Col>
        <Col xs={12}>
          <Cta>
            <CallUsButton onClick={onClickCTA} />
          </Cta>
        </Col>
      </Row>
    </SectionContainer>
  )
}
